define('carnival-frontend/services/ld-client', ['exports', 'ldclient-js', 'carnival-frontend/config/environment'], function (exports, _ldclientJs, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var flagBodyClasses = {
    unified_navigation_experiment: 'unified-navigation-experiment',
    mobile_stui_component: 'mobile-stui-component',
    stui_layout_list: 'stui-layout-list'
  };

  var updateBodyClasses = function updateBodyClasses(flags) {
    var body = document.getElementsByTagName('BODY')[0];

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(flagBodyClasses)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var flagName = _ref2[0];
        var flagClassName = _ref2[1];

        body.classList[flags[flagName] ? 'add' : 'remove'](flagClassName);
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };

  exports.default = Ember.Service.extend({
    flags: Ember.computed('client', function () {
      var client = this.get('client');

      var defaults = {
        audience_tags: false,
        purchase_attribution: false,
        audience_search: false,
        message_recipients_list: false,
        show_automated_message: false
      };

      if (!client) return defaults;

      return Ember.merge(defaults, client.allFlags());
    }),

    setup: function setup(hash) {
      var _this = this;

      var client = _ldclientJs.default.initialize(_environment.default.launchDarkly.apiKey, hash);

      client.on('ready', function () {
        updateBodyClasses(client.allFlags());
        _this.notifyPropertyChange('client');
      });
      client.on('change', function () {
        updateBodyClasses(client.allFlags());
        _this.notifyPropertyChange('client');
      });

      this.set('client', client);
    },
    update: function update(hash) {
      if (!hash) return;

      var client = this.get('client');

      if (!client) {
        this.setup(hash);
      } else {
        client.identify(hash, null);
      }
    },
    getUserHash: function getUserHash(user, app) {
      if (!user) return;

      var hash = {
        key: user.get('id'),
        email: user.get('email')
      };

      if (app) {
        hash.custom = {
          app_id: app.get('id'),
          app_name: app.get('name')
        };
      }

      return hash;
    },


    // eslint-disable-next-line ember/no-observers
    userDidChange: Ember.observer('currentUser.user', 'currentApp.app', function () {
      var user = this.get('currentUser.user'),
          app = this.get('currentApp.app');

      this.update(this.getUserHash(user, app));
    })
  });
});